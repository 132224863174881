.root {
  // position: fixed;
  top: 0;
  // z-index: 100;
  z-index: 11;
  width: 100%;
}

.topGrid {
  text-align: center;
  background-color: #fff;
  align-items: center;
  border: none;
  padding: 0;

  & img,
  & > svg:first-of-type {
    margin-top: 0;
  }
}

.borderGrid {
  border: 1px solid #e7eff7;
  border-radius: 3px;
}

.filtersearch {
  display: flex;
  align-items: center;
  background: #fff;
  height: 54px;
  padding: 0 10px;
}
.filterBtn {
  margin-left: 5px;
  margin-top: 10px;
}
.backBtn {
  margin-left: 4px;
  margin-top: 10px;
  margin-right: 4px;
}
.searchInput {
  border: 0;
  border-radius: 0px;
  width: 100%;
  height: 140px;
  padding-left: 14px;
  font-size: 12px;
}
