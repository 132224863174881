$primary-color: #0056b2;
$secondary-color: #ee6a2a;
$darkorange-color: #cd4b0d;
$gray-color: #666666;
$dark-color: #171717;
$light-white: #f1f1f1;
$color-white: #ffffff;
$colorlight-text: #6f6f6f;
$colorlight-gray: #f3f3ff;
$sidebar-bg-color: #ffffff !important;
$sidebar-color: #adadad !default;
$sidebar-width: 270px !default;
$sidebar-collapsed-width: 80px !default;
$highlight-color: #d8d8d8 !default;
$submenu-bg-color: #2b2b2b !default;
$submenu-bg-color-collapsed: #2b2b2b !default;
$icon-bg-color: #2b2b2b !default;
$icon-size: 35px !default;
$breakpoint-xs: 480px !default;
$breakpoint-sm: 576px !default;
$breakpoint-md: 768px !default;
$breakpoint-lg: 992px !default;
$breakpoint-xl: 1200px !default;

:export {
  primaryColor: $primary-color;
  secondaryColor: $secondary-color;
  darkColor: $dark-color;
  colorlightGray: $colorlight-gray;
  colorWhite: $color-white;
  colorlightText: $colorlight-text;
  darkorangeColor: $darkorange-color;
}
