.navigateButtons {
  background-color: #0056b2;
  border-radius: 50%;
  padding: 0;
  border: none;
  color: #fff;
  cursor: pointer;
  width: 22px;
  height: 22px;
}

.disabled {
  background-color: rgba(0, 86, 178, 0.2);
  cursor: default;
}
