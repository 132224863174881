.main_menu {
  position: relative;
  z-index: 9;
  width: auto;
  transition: all 0.3s ease-in-out;
  color: #fff;
  top: 0;
  right: 0;
  float: none;
  ul {
    list-style-type: none;
    color: #000;
    position: absolute;
    z-index: 1;
    visibility: hidden;
    right: 0px;
    top: 100%;
    margin: 0;
    display: block;
    padding: 0;
    background: #fff;
    min-width: 180px;
    -webkit-box-shadow: 0px 6px 12px 0px rgb(0 0 0 / 18%);
    -moz-box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.175);
    box-shadow: 0px 6px 12px 0px rgb(0 0 0 / 18%);
    transform-origin: 10% top;
    transition: 0.15s linear, 0.1s opacity cubic-bezier(0.39, 0.575, 0.565, 1),
      0.15s transform cubic-bezier(0.1, 1.26, 0.83, 1);
    opacity: 0;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    font-weight: 700;
  }
  ul li {
    list-style: none;
  }
  ul::before {
    bottom: 100%;
    left: 85%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: #fff;
    border-width: 7px;
    margin-left: -7px;
  }
  ul li a {
    border-bottom: 1px solid #787474;
    font-size: 13px;
    text-decoration: none;
    color: #555555 !important;
    display: block;
    padding: 15px 10px;
    line-height: 1;
  }
  ul li a:last-child {
    border-bottom: none;
  }
  ul li a:hover {
    background-color: #f9f9f9;
    color: #2e72bb !important;
    padding-left: 15px;
  }
}

.MuiButton-contained {
  box-shadow: none !important;
}

@media only screen and (max-width: 1204px) {
// .main_menu {
//   display: none;
// }
// .main_menu:nth-of-type(1) {
//   display: none;
// }
}