.root.MuiButton-contained {
  box-shadow: none !important;
}

.hloan {
  position: relative;
  display: flex;
  align-content: center;
}

@media (max-width: 960px) {
  .hloan {
    display: block;
  }
}

@media (max-width: 960px) {
  .hloan img {
    border-right: none;
    display: flex;
    align-items: center;
    margin-right: 0px;
  }
}
@media (max-width: 991px) {
  .hloan img {
    display: block;
  }
}

@media (max-width: 1280px) {
  .hloan img {
    display: none;
  }
}

@media (max-width: 1366px) {
  .hloan img {
    display: none;
  }
}

.hloan img {
  margin-right: 5px;
  margin-left: 0px;
  border-right: 1px solid #ddd;
  height: 37px;
  padding: 8px 5px 8px 0px;
}

.txtName {
  color: #fff;
}

@media (max-width: 1140px) {
  .txtName {
    font-size: 12px;
  }
}

@media (min-width: 821px) {
  .txtName {
    font-size: 12px;
    letter-spacing: -0.3px;
  }
}

@media (min-width: 1280px) {
  .txtName {
    font-size: 14px;
    letter-spacing: -0.3px;
  }
}

@media (max-width: 960px) {
  .buysellbtn {
    display: none !important;
  }
}

@media (max-width: 1280px) {
  .buysellbtn {
    display: none !important;
  }
}
