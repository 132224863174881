.root {
    margin-top: -15px;
    border-radius: 0;
  }
  
  .loginDrawer {
    display: block;
    width: 285px;
    height: auto;
    text-align: center;
    text-transform: capitalize;
  }
  
  .loginDrawer svg {
    margin: 6px;
    cursor: pointer;
  }
  
  .row {
    display: flex;
    border-bottom: 1px solid #0056b20d;
    cursor: pointer;
    align-items: center;
  }
  
  .row img {
    margin: 16px 30px 16px 20px;
  }
  