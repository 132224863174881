.root {
  // boxShadow: "0px 1px 3px 1px #0000001c",
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  position: fixed;
  bottom: -5px;
  z-index: 9999;
  height: auto;

  & img.main {
    height: 22px;
    position: relative;
    margin-bottom: 5px;
  }
}

.addBtn {
  background-color: #fff;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
}

.addIconContainer {
  margin-bottom: 16px;
  width: 78px;
  height: 78px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  position: relative;
  z-index: 1000;
}

.addIconWrapper {
  box-shadow: 0px 3px 5px #00000075;
  background-color: #1c57ac;
  border-radius: 50%;
  width: 62px;
  height: 62px;
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #1c57ac !important;
  }

  & img {
    // height: "auto",
  }
}

.centerWhite {
  position: absolute;
  top: 44%;
  z-index: -1;
  color: #000;
  height: 82px;
  width: 100px;
}

.centerWhiteNormal {
  background: #fff;
  position: absolute;
  top: 21px;
  z-index: -1;
  color: #000;
  height: 82px;
  width: 120px;
  margin-top: 0px;
}

.imgContainer {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  margin-top: 0px;
  border: none !important;
  position: initial;

  & a {
    background-color: transparent;
    display: initial;
    width: auto;
    height: auto;
    padding: 0px;
  }

  & label {
    color: #656666;
    // font-family: 'Open Sans, sans-serif !important';
    font-weight: 700;
    font-size: 13px;
  }
}

.right-rounded {
  border-top-right-radius: 20px;
}

.left-rounded {
  border-top-right-radius: 20px;
}

.navListContainer {
  // border-top-right-radius: 15px;
  // border-top-left-radius: 15px;
  // border-bottom-left-radius: 2px;
  // border-bottom-right-radius: 2px;
  z-index: 9000;
  background-color: #0056b2;
  position: fixed;
  bottom: 46px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 30px 10px;
  height: auto;
  border-radius: none;

  & > button {
    box-shadow: -1px 5px 3px rgba(0, 0, 0, 0.25);
    width: 70%;
    margin: 10px;
    padding: 10px 5px;
    color: #0056b2;
    margin-top: -9px;
    margin-bottom: 25px;
    border: none;
    border-radius: 4px;
    background: #e1e9f7;
    font-weight: 700;
    font-size: 14px;

    & span.black-text {
      font-weight: 700;
      font-size: 14px;
      color: #171717;
    }
  }
}
