.root {
  background: #fff;
  font-family: 'Open Sans', sans-serif;
  border-radius: 10px;
}

.textInput {
  margin: 0 0 16px 0;
  display: block;
  background-color: #fff;

  .MuiOutlinedInput-root {
    border-radius: 6px;
    height: 42px;
    color: #171717;
  }

  .MuiOutlinedInput-input {
    padding: 20px;
  }

  .MuiInputLabel-formControl {
    top: -5px;
  }
}

.formSubmit {
  width: 100%;
  font-size: 16px;
  background-color: #0056b2;
  color: #fff;
  text-transform: capitalize;
  // transition: all 0.3s ease-in-out;
  margin: 25px 0 20px 0;
  border-radius: 7px;

  &:hover {
    box-shadow: none;
    background-color: #0056b2;
    color: #ffffff;
  }
}

.formSignupText {
  background-color: transparent;
  box-shadow: none;
  font-size: 11px;
}

.tabSwitch {
  border: 1px solid #999999;
  border-radius: 30px;
  height: 45px;
  margin: 0 0 10px 0;
}

.tabRoot {
  .MuiTabs-indicator {
    display: none;
  }

  .MuiButtonBase-root.Mui-selected {
    background-color: #ee6a28;
    color: #fff;
    box-shadow: none;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .MuiButtonBase-root {
    border: 30px;
    background-color: #fff;
  }
}

.countrySelect {
  .MuiOutlinedInput-root {
    border-radius: 10px 0 0 10px;
  }
}

.phoneInput {
  .MuiOutlinedInput-root {
    border-radius: 0 10px 10px 0;
  }
}

.socialMuiButton {
  &.MuiButton-contained {
    box-shadow: none !important;
  }

  &:hover {
    box-shadow: 0px 6px 20px #0056b240 !important;
  }
}

.loginFormpd {
  padding: 32px 0 0 0;
}

.loginFormpd1 {
  padding: 20px 0 0 0;
}

.specialText {
  cursor: pointer;
  color: #001ac0;
}

.specialText2 {
  cursor: pointer;
  color: #171717;
  font-weight: 500;
}

.specialText1 {
  cursor: pointer;
  color: #0056b2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingButtonWrapper {
  position: relative;
}

.buttonProgress {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -4px;
  margin-left: -12px;
}

.lineContainer {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin: 1rem 0;
}

.horizontalLine {
  height: 1px;
  width: 100%;
  background-color: #d9d9d9;
  flex-grow: 1;
  flex-shrink: 1;
}

.lineText {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 700;
  padding: 0 1rem;
}

.errorMessage {
  color: #f44336;
  font-size: 0.75rem;
  font-family: sans-serif;
  margin-left: 14px;
}

.otpText {
  font-weight: bold;
  width: 100%;
  padding-bottom: 8px;
}

.codeDetails {
  font-weight: 400;
  width: 100%;
}

.changeBtn {
  color: #028cf2;
  text-transform: capitalize;

  &:hover,
  &:active {
    background: transparent;
  }
}

.resendOtp {
  padding-top: 15px;
}
