$footerContainerBackgroundColor: #f3f3ff;
$footerLinksBackgroundColor: #0056b2;
$breakpoints-mdd: 768px;
$breakpoints-ssm: 576px;
$breakpoints-sm: 992px;

.footerContainer {
  width: 100%;
  opacity: 1;
  padding: 20px;
  background-color: $footerContainerBackgroundColor;
  margin-top: none !important;
  font-size: 0.875rem;
}

.footerContainer1 {
  width: 100%;
  opacity: 1;
  background-color: $footerContainerBackgroundColor;
  margin-top: none !important;
}

.footerLinksContainer {
  background-color: $footerLinksBackgroundColor;
  padding: 15px;
  height: 197px;
  width: auto;
  position: relative;
  z-index: 0;
  border-radius: 0 !important;
  display: block;
  box-sizing: content-box !important;
  // margin-top: 0px !important;
  margin-left: 0px;
  margin-bottom: -40px;

  @media only screen and (min-width: #{$breakpoints-mdd}) {
    width: 97% !important;
  }

  @media only screen and (min-width: #{$breakpoints-ssm}) {
    width: 96%;
  }

  @media only screen and (max-width: #{$breakpoints-sm}) {
    width: auto;
  }
}

.footerLinks {
  // height: 18px;
  width: auto;
  font-size: 13px;
  top: 0% !important;
  position: relative !important;
  z-index: 40 !important;
  border: none !important;
  background: transparent !important;
  font-weight: 400 !important;
  --Grid-rowSpacing:0px!important;
}

.footerLinks1 {
  // height: 18px;
  width: auto;
  font-size: 13px;
  top: 0% !important;
  position: relative !important;
  z-index: 40 !important;
  border: none !important;
  background: transparent !important;
  font-weight: 400 !important;
}
.footerLinks1 ul {
  padding-left: 0px !important;
  padding-top: 0px !important;
  margin-top:0px !important;
}
.footerLinks1 li {
  padding: 4px 0px !important;
  font-weight: 400 !important;
  list-style: none;
}

.footerLinks1 a {
  padding: 4px 0px !important;
  font-weight: 400 !important;
}

.footerLinks1 a:hover {
  color: #0056b2 !important;
}
.footLinks1 {
  margin-left: -14px;
  margin-top: 0px;
}

.footLinks {
  margin-left: -14px;
  margin-top: 0px;
}

.accordion {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  background: transparent !important;
  border: none;
  box-shadow: none;
  background: transparent;
}

.accordionItem {
  border-bottom: 1px solid #ccc;
  // margin-bottom: 10px;
}

.accordionItem h4 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.accordionHeader.MuiGrid-root h4:hover {
  color: #ffffff !important;
}

.accordionHeader {
  // background-color: #f7f7f7;
  cursor: pointer;
  padding: 8px 20px;
  font-weight: bold;
}

.accordionHeader:hover {
  background-color: #f3f3ff !important;
}

.accordionHeader:after {
  background-color: #f3f3ff !important;
}

// .accordionHeader:link {
//   background-color: red !important;
// }

// .accordionHeader:visited {
//   background-color: green !important;
// }

// .accordionHeader:active {
//   background-color: pink !important;
// }

// .accordionHeader:focus {
//   background-color: #0056B2 !important;
//   color: #FFF !important;
// }

// .accordionHeader:visited {
//   background-color: #0056B2 !important;
//   color: #FFF !important;
// }

.accordionContent {
  display: none;
  padding: 0px 20px;
}

.active {
  background-color: transparent !important;
}

.accordionHeader h4 {
  font-size: 16px !important;
  font-weight: 700;
  color: #0056b2;
}
