.root {
    padding: 0px!important;
    border: 1px solid #dedede!important;
    background: #fff!important;
    height: 38px!important;
    margin-top: -1px!important;
    width: 448px!important;
     @media (max-width: 1024px) {
        width: 430px!important;
      }

    @media (max-width: 991px) {
        width: 410px!important;
      }
       @media (max-width: 960px) {
        width: 430px!important;
      }
  }

   .moduleWidth {
    width: 100%!important;
    max-width: 70px!important;
  }
  
  .cityWidth {
    width: 100%!important;
    max-width: 115px!important;
  }
  
  .areaWidth {
    width: 56%!important;
    max-width: 230px!important;
    @media (max-width: 1024px) {
        width: 200px!important;
      }
    @media (max-width: 960px) {
        width: 50%!important;
      }
  }
  
  .searchButton {
    color: #ffffff!important;
    background-color: transparent!important;
  
    & > button {
      box-shadow: none !important;
      background-color: transparent !important;
      padding: 12px 2px !important;
      margin-left: -10px!important;
    }
  }
  
  .cityDropdownRoot {
    text-transform: capitalize!important;
    height: 36px !important;
    position: relative!important;
  
    &::after {
      border-bottom: 2px solid #171717!important;
      border-right: 2px solid #171717!important;
      content: ''!important;
      display: block!important;
      height: 8px!important;
      margin-top: -5px!important;
      pointer-events: none!important;
      position: absolute!important;
      right: 10px!important;
      top: 50%!important;
      transform-origin: 66% 66%!important;
      transform: rotate(45deg)!important;
      transition: all 0.15s ease-in-out!important;
  
      @media (max-width: 600px) {
        right: 10px!important;
      }
    }
  }
  
  .cityDropdownInner {
    border-top-left-radius: 0!important;
    border-bottom-left-radius: 0!important;
    height: 36px !important;
    
    &::before {
      right: 19px !important;
    }
  }
  
  .infoSection {
    display: flex!important;
    align-items: center!important;
  }
  
  .info {
    padding: 10px!important;
  
    &:not(:first-of-type) {
      margin-top: 10px!important;
    }
  
    h5 {
      padding-left: 5px!important;
      color: #0056b2!important;
      font-size: 22px!important;
      font-weight: 700!important;
    }
  
    p {
      color: #363636!important;
      font-size: 16px!important;
    }
  }
  
 
  