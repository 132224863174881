@import 'variables.module.scss';
@import '~react-image-gallery/styles/scss/image-gallery.scss';

html {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}
strong,
b {
  font-weight: 700;
}
body {
  margin: 0;
  font-size: 0.875rem !important;
  font-family: Open Sans, sans-serif !important;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: normal !important;
  background-color: #fafafa !important;
  padding: 0;
  overflow: auto !important;
  font-family: 'Open Sans', sans-serif;
  font-display: swap;
}
@media print {
  body {
    background-color: #fff !important;
  }
}
body::backdrop {
  background-color: #fafafa;
}

//end css

form::-webkit-scrollbar {
  width: 4px;
}

// ::-webkit-scrollbar {
//   display: none;
// }

form::-webkit-scrollbar-thumb {
  background-color: #dddddd;
  outline: 1px solid #dddddd;
  border-radius: 12px;
}

/* Scroll Component CSS */
a {
  color: $primary-color;
  text-decoration: none;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  outline: 0;
}
li.MuiBreadcrumbs-li a {
  color: #171717 !important;
}

small {
  font-weight: bold !important;
  display: inline-block;
  color: #666666;
  font-size: 0.875rem !important;
  text-transform: capitalize;
  overflow: hidden;
}
h5,
h4,
p {
  margin: 0;
  font-family: Open Sans, sans-serif !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 35px white inset !important;
  background-color: #000 !important;
}

.swiper-button-next,
.swiper-button-prev {
  position: absolute;
  width: 35px !important;
  height: 35px !important;
  padding: 7px 10px;
  border: 1px solid #ddd;
  box-shadow: rgb(0 0 0 / 25%) 0px 1px 4px 0px;
  background-color: rgb(255, 255, 255);
  margin-top: -22px !important;
  z-index: 10;
  cursor: pointer !important;
}
.swiper-button-next {
  // @include SbtnSize;
  right: 0;
  top: 50%;
}
// .swiper-button-prev {
// @include SbtnSize;
// }
.loading {
  width: 22vw;
  height: 50vh;
  position: relative;
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  background-color: $color-white;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  margin-bottom: 3px;
  padding: 0 10px 0 0;
  color: $color-white;
  background-size: 200% 200%;
  -webkit-animation: Gradient 15s ease infinite;
  -moz-animation: Gradient 15s ease infinite;
  animation: Gradient 15s ease infinite;
}
.no-gutters {
  margin-right: 0 !important;
  margin-left: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

button:focus {
  outline: 0;
}

.card {
  border: none;
}

.ReactModal__Overlay {
  z-index: 9999;
  background-color: rgb(0 0 0 / 70%) !important;
}

figure {
  margin: 0;
}

.closeIcon {
  color: black;
  position: absolute;
  right: 10px;
  top: 10px;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../pages/fonts/OpenSans-Regular-webfont.woff2') format('woff2'),
    url('../pages/fonts/OpenSans-Regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../pages/fonts/OpenSans-Bold-webfont.woff2') format('woff2'),
    url('../pages/fonts/OpenSans-Bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: bold;
  font-display: swap;
}


.property-card-item {
  padding-right: 9px !important;
  // padding-right: 8px !important;
}

.property-card-item:last-child {
  padding-right: 6px !important;
}
.mgrpdf__wrapper {
  position: relative;
}
.mpdetailsps1 span.PrivateTabIndicator {
  background-color: #0056b2;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.tabDetails span.PrivateTabIndicator {
  height: 0px !important;
  background-color: transparent !important;
}

//for html tables

.commfaq .MuiBox-root figure.table > table {
  border-collapse: collapse;
}
.commfaq .MuiBox-root figure.table tr > td {
  border: 1px solid;
  padding: 4px;
}

@media (max-width: 390px) {
  li::marker {
    font-size: 15px;
  }
}

input,
select,
option {
  font-family: 'Open Sans', sans-serif !important;
}
