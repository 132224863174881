@import '/styles/variables.module.scss';

.socialIcons {
  background-image: url(../public/static/images/social_icons.svg);
  background-repeat: no-repeat;
}
.facebook,
.instagram,
.linkdin,
.twitter,
.youTube {
  height: 45px;
  width: 50px;
}
.instagram {
  background-position: -50px;
}
.linkdin {
  background-position: -100px;
}

.twitter {
  background-position: -150px;
}

.youTube {
  background-position: -200px;
}

.version_10 {
  text-align: left !important;
  background-repeat: repeat;
  border-top: 0px solid #ededed;
}
#footer {
  background: #f2f8ff;
  padding: 0 0 30px 0;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
}

#footer p {
  padding-top: 12px;
}
.yel {
  background: #e39516;
  height: 3px;
}
.tea {
  background: #0bb36d;
  height: 3px;
}
.ora {
  background: #e02f44;
  height: 3px;
}
.blue {
  background: #2c72b0;
  height: 3px;
}
.footer h3 {
  margin: 0 0 0px 0;
  font-size: 16px;
  font-weight: bold !important;
}
.footer h4 {
  font-size: 16px;
  font-family: Open Sans, sans-serif !important;
  font-weight: 700;
}
.locality_footer h4 {
  font-size: 16px;
  font-family: Open Sans, sans-serif !important;
  font-weight: 700;
}

.locality_footer h3 {
  margin: 0 0 0px 0;
  font-size: 16px;
  font-weight: 700 !important;
}

.footerText {
  margin-bottom: 2px !important;
}
.footerText1 {
  margin-top: 50px !important;
}
.viewBtnSpacing {
  margin-bottom: 0px;
}
@media only screen and (max-width: 600px) {
  .footer h3 {
    margin: 0 0 0px 0;
  }
  .locality_footer h3 {
    margin: 0 0 0px 0;
  }
  .spanblack {
    margin-top: -10px !important;
    display: block;
  }
  .propHeading h3 {
    padding-left: 0px !important;
  }

  .spanblackHead {
    // margin-top: -10px !important;
    display: block;
  }
  .keyDetails {
    // height: 300px;
    // overflow: scroll;
    height: max-content;
    margin-bottom: 34px;
    margin-top: 20px;
  }

  .footerText {
    margin-bottom: -10px !important;
    margin-top: -30px !important;
    // padding-left: 15px;
    color: #363636;
  }
  .viewBtnSpacing {
    margin-bottom: 45px;
  }
  .footerText1 {
    margin-top: 0px !important;
  }
}
.spanblue {
  color: #004b99;
  font-weight: 700;
}

.spblack {
  color: #171717;
  font-weight: 700;
}
.dataContent {
  margin-top: 44px;
}

.dataContent p {
  padding-bottom: 12px;
  font-size: 14px;
}

.dataContent ul {
  padding-left: 20px;
}

.dataContent li {
  font-size: 14px;
}

.spanblack {
  color: #363636 !important;
  font-weight: 700;
}

.spanblackHead {
  color: #363636 !important;
  font-weight: 700;
}

.spanblack1 {
  color: $primary-color !important;
  font-weight: bold !important;
  font-size: 18px !important;
}
.spanorange {
  color: #cd4b0d !important;
  font-weight: 700;
}
.quicklink {
  font-size: 13px;
  font-weight: 700;
  color: #363636 !important;
  cursor: pointer;
  line-height: 30px;
}

.additional_links {
  margin: 0;
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  text-align: right;
  font-weight: 400;
  line-height: 30px;
}
.additional_links li a {
  color: rgba(255, 255, 255);
  // opacity: 0.9;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  // padding-top: 11px;
  // padding-bottom: 11px;
  &:hover {
    color: rgba(255, 255, 255, 1);
  }
}

.additional_links li:after {
  content: '|';
  font-weight: 700;
  position: relative;
  left: 10px;
}

.additional_links li:first-of-type {
  margin-right: 20px;
}

.additional_links li {
  display: inline-block;
  margin-right: 15px;
}

.additional_links li:last-child:after {
  content: '';
}

.footerData {
  background-color: #0056b2;
  color: #ffffff;
  padding-top: 14px;
  padding-bottom: 14px;
  text-align: center;
  margin-top: 20px;
}

.propHead {
  width: 98%;
}

.propHead h3 {
  font-size: 18px !important;
}
@media (max-width: 600px) {
  .dataContent {
    padding-left: 5px;
  }
  .spanblack1 {
    font-size: 15px;
    margin-top: 1.5rem;
    display: block;
    // padding-left: 15px;
  }

  .spanblackHead {
    font-size: 15px;
    margin-top: 1.5rem;
    display: block;
  }
  .additional_links li {
    margin-bottom: 0.3rem;
  }
  .additional_links a {
    color: white !important;
    font-weight: 700;
    font-size: 13px;
  }
  .spanblack {
    margin-top: 1.5rem;
    display: block;
    font-size: 15px;
  }

  .footerSpanblack {
    display: block;
    font-weight: bold;
  }
  .footerData {
    padding-bottom: 0px;
  }

  .footerData > div {
    display: flex;
    flex-direction: column;
    & > div:nth-of-type(1) {
      order: 2;
      max-width: 100%;
      flex-basis: 100%;
    }
    & > div:nth-of-type(2) {
      order: 1;
      & ul {
        & li::after {
          content: '';
        }
        padding: 1rem 1.5rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
}
