.root {
    position: absolute;
    z-index: 2;
    background-color: #ffffff;
    border: 1px solid #d9e5f4;
    box-shadow: none !important;
    border-radius: 0px;
    overflow-y: scroll;
    border-top: none;
  }
  
  .rootwithnocontaint {
    position: absolute;
    z-index: 2;
    background-color: #ffffff;
    border: 1px solid #dadee3;
    box-shadow: 0px 3px 8px #00000029;
    opacity: 1;
    overflow-y: auto;
    height: auto;
  }
  
  .paperListRoot {
    list-style: none;
    text-align: left;
    display: block;
    margin: 0;
    padding: 0;
  
    li {
      padding: 10px 20px;
      font-size: 14px;
    }
  
    div:first-of-type li {
      border-top: none;
    }
  }
  
  .noSearchfountText {
    text-align: center;
    font: normal normal bold 14px/19px 'Open Sans';
    color: #2f2f2f;
    letter-spacing: 0px;
    padding-left: 25px;
    padding-top: 17px;
    display: flex;
    min-height: 60px;
  }
  
  .listItem {
    text-transform: capitalize;
    color: #171717;
    cursor: pointer;
    justify-content: space-between;
    padding: 0px 20px;
  }
  
  .active {
    background-color: #f2f5fc;
    color: #0056b2;
  }
  
  .inactive {
    color: #171717;
    background-color: #ffffff;
  }
  
  .stickyCities {
    padding: 20px 10px;
    list-style: none;
  }
  
  .menuHeading {
    opacity: 0.5;
    font-size: 11px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
  }
  
  .heading {
    line-height: 1.5rem;
    color: #171717;
    font-size: 14px;
    text-transform: capitalize;
    background-color: #fff;
    border: 1px solid #dedede;
    border-left: none;
    border-right: none;
    font-weight: 700;
  }
  