// .sidebarRootClass {
//   font-family: 'Open Sans, sans-serif';
// }

.rootNav button {
  color: #333333;
  padding: 6px;
  border: 1px solid #e2e2e2;
  border-radius: 0;
  background: #fff;
  margin-right: 5px;
  height: 39px;
}

// @media (max-width: 960px) {
//   .rootNav button {
//     display: block;
//   }
// }

@media (max-width: 959px) {
  .rootNav button {
    display: none;
  }
}

.NavLogin {
  color: #171717 !important;
  padding: 6px 0 !important;
  border: 1px solid #e2e2e2 !important;
  border-radius: 0 !important;
  background: #fff !important;
  margin-right: 5px !important;
  height: 38px !important;
  max-width: 44px !important;
  min-width: 44px !important;
}

.NavLogin svg {
  margin-top: 7px !important;
}

.NavLogin:hover {
  background-color: #fff !important;
}

.triangle {
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-top: 0;
  border-bottom: 14px solid #ffffff;
  position: absolute;
  right: 27px;
  top: 30px;
  z-index: 9999;
}

.WishlistBtn {
  color: #171717 !important;
  padding: 18px 0 !important;
  border: 1px solid #e2e2e2 !important;
  border-radius: 0 !important;
  background: #fff !important;
  margin-right: 5px !important;
  height: 38px !important;
  max-width: 44px !important;
  min-width: 44px !important;
}

.WishlistBtn:hover {
  background-color: #fff !important;
}

.menutext {
  display: flex !important;
}

@media only screen and (max-width: 960px) {
  .menutext {
    display: none;
  }
}
