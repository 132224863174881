.arrowTopButton {
    position: fixed;
    right: 25px;
    bottom: 60px;
    z-index: 99999;
    color: #fff;
    text-align: center;
    cursor: pointer;
    height: 36px;
    width: 36px;
    vertical-align: middle;
    transition: 0.3s; /* Hover effect moved to here */
    object-fit: cover;
    object-position: -178px 0px; /* Moved from inline styles */
  }
  
  .arrowSize {
    font-size: 30px !important;
    vertical-align: middle;
    padding-top: 4px;
  }
  